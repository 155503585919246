/* Member View, Vue Component */
<template>
  <div>
    <div v-if="true">
    <member-summary v-if="entity && entity.Id && !loading"
      :title="`Member - ${entity.Reference} - ${entity.FullName}`"
      :member="entity">

          <Can I="update" on="Customers">
            <v-btn v-if="!entity.EmailValidated" color="primary" class="me-3" @click.prevent="SendEmailValidation">
	            <v-icon size="18" class="me-1"> {{ icons.mdiEmail }}</v-icon>
              <span>Send Email Validation</span>
            </v-btn>
          </Can>
          <Can I="admin" on="Customers">
            <v-btn color="primary" class="me-3" :to="{ name: 'member-edit', params: { id: entity.Id } }">
	            <v-icon size="18" class="me-1"> {{ icons.mdiPencilOutline }}</v-icon>
              <span>Edit</span>
            </v-btn>
          </Can>
          <Can I="update" on="Customers">
            <action-confirm-dialog @confirmed="(comment) => ToggleMemberEnabled(false, comment)" dialogTitle="Disable Member" v-if="entity && entity.Enabled">
            <template v-slot:information>
              <p>This customer <strong>will not</strong> be able to login or order.</p>
            </template>
            <v-btn color="primary" class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiAccountOff }}</v-icon>
              <span>Disable Customer</span>
            </v-btn>
          </action-confirm-dialog>
          </Can>
          <Can I="update" on="Customers">
            <action-confirm-dialog @confirmed="(comment) => ToggleMemberEnabled(true, comment)" dialogTitle="Enable Member" v-if="entity && !entity.Enabled">
            <template v-slot:information>
              <p>This customer <strong>will be</strong> able to login or order.</p>
            </template>
            <v-btn color="primary" class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiAccount }}</v-icon>
              <span>Enable Customer</span>
            </v-btn>
          </action-confirm-dialog>
          </Can>
          <v-btn color="secondary"
                 class="me-3"
                 @click="Back">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
    </member-summary>

    <v-card v-if="entity && !loading" id="member-view" class="entity-view mt-6">

      <v-tabs v-model="currentTab">
        <v-tab :to="'#Overview'">Overview</v-tab>
        <v-tab :to="'#Addresses'">Addresses</v-tab>
        <v-tab :to="'#Orders'">Orders</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
      <v-tab-item value="Overview">
        <v-row>
        <v-col cols="12" md="6">
           <v-simple-table>
      <thead>
        <tr>
          <th colspan="2">DETAILS</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Email</td>
          <td><a :href="`mailto:${entity.Email}`">{{entity.Email}}</a></td>
        </tr>

        <tr>
          <td>Salutation</td>
          <td>{{ entity.Salutation }}</td>
        </tr>

        <tr>
          <td>First Name</td>
          <td>{{ entity.FirstName }}</td>
        </tr>

        <tr>
          <td>Last Name</td>
          <td>{{ entity.LastName }}</td>
        </tr>

        <tr>
          <td>DOB</td>
          <td>{{ Display.DisplayDate(entity.DOB) }}</td>
        </tr>

        <tr>
          <td>Telephone</td>
          <td>{{ entity.Telephone }}</td>
        </tr>

      </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="12" md="6">
           <v-simple-table>
      <thead>
        <tr>
          <th colspan="2">INFO</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Marketing</td>
          <td><v-icon size="18" :color="booleanIcons(entity.Marketing).variant">{{ booleanIcons(entity.Marketing).icon }}</v-icon></td>
        </tr>

        <tr>
          <td>Partner Marketing</td>
          <td><v-icon size="18" :color="booleanIcons(entity.PartnerMarketing).variant">{{ booleanIcons(entity.PartnerMarketing).icon }}</v-icon></td>
        </tr>

        <tr>
          <td>Created</td>
          <td>{{ Display.DateTime(entity.Created) }}</td>
        </tr>

        <tr>
          <td>LastLogin</td>
          <td>{{ Display.DateTime(entity.LastLogin) }}</td>
        </tr>
        <tr>
          <td>Enabled</td>
          <td><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></td>
        </tr>

      </tbody>
          </v-simple-table>
        </v-col>
        </v-row>
        <v-container class="mx-1">
          <div class="text-body-2">Comments</div>
          <div class="text-body-2"><pre>{{ entity.Comments }}</pre></div>
        </v-container>
      </v-tab-item>
      <v-tab-item value="Addresses">
        <member-address-list v-if="entity.Id" :MemberId="entity.Id"></member-address-list>
      </v-tab-item>
      <v-tab-item value="Orders">
        <order-list title="" :hideFilter="true" :defaultFilter="orderListFilter"></order-list>
      </v-tab-item>
      </v-tabs-items>

    </v-card>
    </div>
    <!-- <v-card v-if="entity && !loading" id="member-view" class="entity-view">
    <v-card-title>{{entity.Reference}} - {{entity.FirstName}} {{entity.LastName}}</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col class="d-flex align-center flex-wrap pb-0">
          <Can I="admin" on="Customers">
            <v-btn color="primary" class="me-3" :to="{ name: 'member-edit', params: { id: entity.Id } }">
	            <v-icon size="18" class="me-1"> {{ icons.mdiPencilOutline }}</v-icon>
              <span>Edit</span>
            </v-btn>
          </Can>
          <Can I="update" on="Customers">
            <action-confirm-dialog @confirmed="(comment) => ToggleMemberEnabled(false, comment)" dialogTitle="Disable Member" v-if="entity && entity.Enabled">
            <template v-slot:information>
              <p>This customer <strong>will not</strong> be able to login or order.</p>
            </template>
            <v-btn color="primary" class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiAccountOff }}</v-icon>
              <span>Disable Customer</span>
            </v-btn>
          </action-confirm-dialog>
          </Can>
          <Can I="update" on="Customers">
            <action-confirm-dialog @confirmed="(comment) => ToggleMemberEnabled(true, comment)" dialogTitle="Enable Member" v-if="entity && !entity.Enabled">
            <template v-slot:information>
              <p>This customer <strong>will be</strong> able to login or order.</p>
            </template>
            <v-btn color="primary" class="me-3">
              <v-icon size="18" class="me-1">{{ icons.mdiAccount }}</v-icon>
              <span>Enable Customer</span>
            </v-btn>
          </action-confirm-dialog>
          </Can>
        </v-col>
        <v-col class="text-right">
          <v-btn color="secondary"
                 class="me-3"
                 @click="Back">
            <v-icon size="18"
                    class="me-1">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-simple-table>
      <thead>
        <tr>
          <th colspan="2">MEMBER DETAILS</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Reference</td>
          <td>{{ entity.Reference }}</td>
        </tr>

        <tr>
          <td>Email</td>
          <td>{{ entity.Email }}</td>
        </tr>

        <tr>
          <td>First Name</td>
          <td>{{ entity.FirstName }}</td>
        </tr>

        <tr>
          <td>Last Name</td>
          <td>{{ entity.LastName }}</td>
        </tr>

        <tr>
          <td>Salutation</td>
          <td>{{ entity.Salutation }}</td>
        </tr>

        <tr>
          <td>Country Name</td>
          <td>{{ entity.CountryId_Name }}</td>
        </tr>

        <tr>
          <td>DOB</td>
          <td>{{ Display.DisplayDate(entity.DOB) }}</td>
        </tr>

        <tr>
          <td>Telephone</td>
          <td>{{ entity.Telephone }}</td>
        </tr>

        <tr>
          <td>Partner Marketing</td>
          <td><v-icon size="18" :color="booleanIcons(entity.PartnerMarketing).variant">{{ booleanIcons(entity.PartnerMarketing).icon }}</v-icon></td>
        </tr>

        <tr>
          <td>Created</td>
          <td>{{ Display.DateTime(entity.Created) }}</td>
        </tr>

        <tr>
          <td>LastLogin</td>
          <td>{{ Display.DateTime(entity.LastLogin) }}</td>
        </tr>
        <tr>
          <td>Enabled</td>
          <td><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></td>
        </tr>

        <tr>
          <td>Comments</td>
          <td><pre>{{ entity.Comments }}</pre></td>
        </tr>

      </tbody>
    </v-simple-table>

  </v-card> -->
  </div>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import { Can } from '@casl/vue'
  import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'
  import MemberSummary from './components/MemberSummary'
  import OrderList from '@/views/order/OrderList'
  import MemberAddressList from '@/views/address/MemberAddressList'

  import {
    mdiPencilOutline,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
    mdiChevronLeft,
    mdiAccountOff,
    mdiAccount,
    mdiEmail
  } from '@mdi/js'

  import { ref, watch, computed } from '@vue/composition-api'

  export default {
    components: {
      Can,
      ActionConfirmDialog,
      MemberSummary,
      OrderList,
      MemberAddressList
    },
    setup() {

      const { route, router } = useRouter();

      const entity = computed(() => store.state.app.Member);
      const loading = ref(false);

      const fetchData = (id) => {
        store
          .dispatch('app/fetchMember', {
            id: id
          })
          .then(response => {
            loading.value = false
          })
          .catch(error => {
            console.log(error);
            router.push({ name: 'member-list' });
          })
      }

      // fetch the user information when params change
      watch(() => route.value.params.id, (id) => fetchData(id));

      // and call now
      fetchData(route.value.params.id);

      const orderListFilter = computed(() => {
        return { MemberId: store.state.app.Member.Id };
      });

      const booleanIcons = status => {
        if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
        return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
      }

      const Back = () => {
        router.go(-1);
      };

      const ToggleMemberEnabled = (enabled, comment) => {
        console.log("ToggleMemberEnabled", enabled, comment);

        store
          .dispatch('app/ToggleEnabledMember', {
            id: entity.value.Id,
            enabled,
            comment
          })
      };

      const SendEmailValidation = () => {
        console.log("SendEmailValidation");

        store
          .dispatch('app/SendEmailValidation', {
            id: entity.value.Id
          })
      };

      const currentTab = ref(0)

      return {
        SendEmailValidation,
        orderListFilter,
        currentTab,
        ToggleMemberEnabled,
        Back,
        loading,
        entity,
        Display,
        booleanIcons,
        icons: {
          mdiPencilOutline,
          mdiChevronLeft,
          mdiAccountOff,
          mdiAccount,
          mdiEmail
        },
      };
    }
  }
</script>

<style lang="scss" scoped>
  .entity-view::v-deep .v-data-table th:last-child {
    width: 66%;
  }
</style>

