/* Address List, Vue Component */
<template>
  <div id="address-list" class="d-flex flex-wrap ma-6">
    <v-card outlined v-for="item in items" :key="item.Id" class="mx-2 my-2" :class="item.IsDeleted ? 'deleted' : ''">
      <v-card-text>
        <div>
        <span v-if="item.Salutation">{{ item.Salutation }} </span>{{ item.FullName }}<br />
        <span v-if="item.Address1">{{ item.Address1 }}</span><br v-if="item.Address1" />
        <span v-if="item.Address2">{{ item.Address2 }}</span><br v-if="item.Address2" />
        <span v-if="item.Address3">{{ item.Address3 }}</span><br v-if="item.Address3" />
        <span v-if="item.Address4">{{ item.Address4 }}</span><br v-if="item.Address4" />
        <span v-if="item.CountryId_Name">{{ item.CountryId_Name }}</span><br v-if="item.CountryId_Name" />
        <span v-if="item.Postcode">{{ item.Postcode }}</span><br v-if="item.Postcode" />
        </div>
        <div class="mt-3" v-if="item.Telephone">{{ item.Telephone }}
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
      <v-btn icon @click.prevent.stop="CopyAddress(item)"><v-icon size="22">{{ icons.mdiContentCopy }}</v-icon></v-btn>
      <v-btn icon :to="{ name: 'address-view', params: { id: item.Id } }"><v-icon size="22">{{ icons.mdiEyeOutline }}</v-icon></v-btn>
      <v-btn icon :to="{ name: 'address-edit', params: { id: item.Id } }"><v-icon size="22">{{ icons.mdiPencilOutline }}</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import store from '@/store'

import {
  mdiContentCopy,
  mdiEyeOutline,
  mdiPencilOutline
} from '@mdi/js'

import { ref, watch, computed, toRefs } from '@vue/composition-api'

export default {
   props: {
    MemberId: {
      required: true,
      type: String
    }
  },
  setup(props) {

  const { MemberId } = toRefs(props);

  // threee params for fetching
  const search = ref({});
  const filter = ref({
    MemberId: MemberId.value
  });
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
    itemsPerPage: 40
  });

  // items
  const items = computed(() => store.state.app.Addresses || []);
  const totalItems = computed(() => store.state.app.AddressesTotal || 0);
  const loading = ref(false);

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchAddresses', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });
  fetchData()

  const CopyAddress = (address) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {

      let text = "";
      if (address.Salutation) text = address.Salutation + " ";
      text = text + address.FullName + "\n";
      if (address.Address1) text = text + address.Address1 + "\n";
      if (address.Address2) text = text + address.Address2 + "\n";
      if (address.Address3) text = text + address.Address3 + "\n";
      if (address.Address4) text = text + address.Address4 + "\n";
      if (address.CountryId_Name) text = text + address.CountryId_Name + "\n";
      if (address.Postcode) text = text + address.Postcode + "\n";

      navigator.clipboard.writeText(text);
    }
  }

return {
      CopyAddress,
      search,
      filter,
      options,
      totalItems,
      items,
      loading,
      icons: {
        mdiContentCopy,
        mdiEyeOutline,
        mdiPencilOutline
      },
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .deleted {
  text-decoration: line-through
}
::v-deep .v-icon:hover {
  opacity: 1.0;
}
</style>
